angular
  .module("app", [
    "ui.router",
    "ngSanitize",
    "com.2fdevs.videogular",
    "com.2fdevs.videogular.plugins.controls",
    "com.2fdevs.videogular.plugins.overlayplay",
    "com.2fdevs.videogular.plugins.poster",
    "ngScrollbars",
    "duScroll"
  ])

  .config(function(ScrollBarsProvider) {
    // scrollbar defaults
    ScrollBarsProvider.defaults = {
      autoHideScrollbar: false,
      scrollInertia: 600,
      axis: "yx",
      advanced: {
        updateOnContentResize: true
      },
      scrollButtons: {
        scrollAmount: "auto", // scroll amount when button pressed
        enable: true // enable scrolling buttons by default
      }
    };
  });
