function worksGridCtrl($timeout, $rootScope, $document) {
  this.$onInit = function() {
    // Video player configuration
    this.config = {
      sources: this.videos[0].sources,
      theme: "vendor/videogular.css",
      plugins: {
        poster: ""
      }
    };

    this.videoPlaying = "";
    this.videoPlayerClass = "";
  };

  this.scrollConfig = {
    theme: "rounded-dots",
    axis: "x",
    setLeft: 0
  };

  this.fadeGrid = function() {
    const _this = this;
    $timeout(function() {
      _this.gridClass = "show";
    }, 1000);
  };

  this.playVideo = function(index) {
    this.api.stop();

    // Scroll to top of grid first
    var workEl = angular.element(document.getElementById("work"));
    $document.scrollTo(workEl, 0, 500);

    this.config.sources = this.videos[index].sources;

    $timeout(this.api.play.bind(this.api), 100);
    this.videoPlayerClass = "playing";
  };

  this.playerReady = function(api) {
    this.api = api;
  };

  this.showPlayer = function() {
    this.api.play();
    this.videoPlayerClass = "playing";

    $rootScope.overflowBody = true;
  };

  this.hidePlayer = function(e) {
    e.preventDefault();
    this.api.stop();

    this.videoPlayerClass = "";

    $rootScope.overflowBody = false;
  };
}

angular
  .module("app")

  .component("worksGrid", {
    templateUrl: "app/directives/worksGrid/worksGrid.html",
    scope: {},
    bindings: {
      works: "<",
      videos: "<"
    },
    controller: worksGridCtrl
  });
