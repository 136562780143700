angular.module('app')

.controller('MainCtrl',
    function (
        $scope, 
        $document, 
        $timeout,
        reelConfig, 
        reelWorkList, 
        workVideos, 
        works,
        preloader
    ) {

        $scope.date = new Date();
        $scope.navigationState = 'closed';

        $scope.imagesLoaded = false;

        // Images to be preloaded
        $scope.imageLocations = [
            'assets/images/ny-logo.png',
            'assets/images/intro.jpg',
            'assets/images/work.jpg',
            'assets/images/cv.jpg',
            'assets/images/contact.jpg',
            'assets/images/work-thumbs/Cliff_Diving_Thumbnail.jpg',
            'assets/images/work-thumbs/Disney_Thumbnail.jpg',
            'assets/images/work-thumbs/The_Fourth_Phase_Thumbnail.jpg',
            'assets/images/work-thumbs/Sony_Music_Thumbnail.jpg',
            'assets/images/work-thumbs/TOTM_Thumbnail.jpg',
            'assets/images/work-thumbs/Westworld_Now_TV_Thumbnail.jpg',
            'assets/images/work-thumbs/Now_TV_Cineworld_Thumbnail.jpg',
            'assets/images/work-thumbs/The_Martian_UHD_thumbnail.jpg'
        ];

        // Preload images
        preloader.preloadImages($scope.imageLocations)
        .then(function() {
            $scope.imagesLoaded = true;
        }, function () {
            console.log('Error preloading images');
        });
    
        // Reel section configuration
        $scope.reelConfig = reelConfig.data;
        $scope.reelWorkList = reelWorkList.data;

        // Works section configuration
        $scope.works = works.data;
        $scope.workVideos = workVideos.data;

        // Helper function for navigation
        $scope.gotoSection = function (section) {
            
            $scope.toggleNav();
            
            $timeout(function() {
                var sectionEl = angular.element(document.getElementById(section));
                $document.scrollTo(sectionEl, 0, 1000);
            }, 600);
        }

        // Toggle navigation
        $scope.toggleNav = function () {
            $scope.navigationState = $scope.navigationState === 'closed' ? 'open' : 'closed'; 
        }

});