function videoPlayerCtrl($rootScope, $timeout) {
  this.$onInit = function() {
    this.videoPlayerClass = "";
    this.listExpanded = null;
    this.fadeIntroText();
  };

  this.scrollConfig = {
    theme: "light-thick"
  };

  this.fadeIntroText = function() {
    const _this = this;
    $timeout(function() {
      _this.introTextClass = "show";
    }, 100);
  };

  this.playerReady = function(api) {
    this.api = api;
  };

  this.showPlayer = function(e) {
    e.preventDefault();
    this.api.play();

    this.introTextClass = "";
    this.videoPlayerClass = "playing";

    $rootScope.overflowBody = true;
  };

  this.hidePlayer = function(e) {
    e.preventDefault();
    this.api.stop();

    this.videoPlayerClass = "";
    this.introTextClass = "show";
    this.listExpanded = false;

    $rootScope.overflowBody = false;
  };

  this.jumpTo = function(time) {
    this.api.seekTime(time);
  };

  this.showWorkList = function() {
    this.listExpanded = !this.listExpanded ? true : false;
  };
}

angular
  .module("app")

  .component("videoPlayer", {
    templateUrl: "app/directives/videoPlayer/videoPlayer.html",
    scope: {},
    bindings: {
      config: "<",
      reelWorks: "<"
    },
    controller: videoPlayerCtrl
  });
