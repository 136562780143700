angular.module('app')

.config(function ($stateProvider, $urlRouterProvider) {

    $urlRouterProvider.otherwise('/');

    $stateProvider
    .state('homepage', {
        url: '/',
        templateUrl: 'app/views/main.html',
        controller: 'MainCtrl',
        resolve: {
            reelConfig: function(LocalApi) {
                return LocalApi.getJsonContent('reel-config');
            },
            reelWorkList: function (LocalApi) {
                return LocalApi.getJsonContent('reel-work-list');
            },
            workVideos: function (LocalApi) {
                return LocalApi.getJsonContent('work-videos');
            },
            works: function (LocalApi) {
                return LocalApi.getJsonContent('work');
            },
        }
    });

});